body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
}
.swyble-intro {

  font-family: Arial, sans-serif;
  display: flex;
place-items: center;
  padding: 32px;
  border-radius: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  height: auto;
  width: 640px;
  flex-direction: column;
/*   border: 10px solid rgba(0, 0, 0, 0.7); */
text-align: center;
}

.center1 {
  color: white;

  text-align: center;
}
.center1 h1 {
  font-size: 3em;
  text-shadow: 0.1em 0.1em rgba(0, 0, 0, 0.9);
  font-weight: 100;
  margin-bottom: 2px;

  font-weight: 700;
}

.center1 h2 {
  line-height: 1;
  margin: 0;
  font-family: sans-serif;
  text-shadow: 0.01em 0.01em black;
  font-size: 2em;
  margin-top: 16px;
  font-weight: 400;
}
.swyble-intro img {
  max-width: 100%;
  height: auto;
}

.gradient-text {
  /* Define the gradient background */
  background: linear-gradient(120deg, white, #ffcb47);

  /* For Firefox */
  -moz-background-clip: text;
  /* For WebKit browsers */
  -webkit-background-clip: text;

  /* Make the text transparent */
  color: transparent;

  /* For WebKit browsers to ensure the gradient shows */
  -webkit-text-fill-color: transparent;
}
.poster{
  justify-content: center;
  align-items: center;
  display:flex;
  flex-direction: column;
  gap:16px
}
.qr{
  height: calc(100vh - 160px);

  width: calc(90vw);
  justify-content: center;
  align-items: center;
  display:flex;
  flex-direction: row;
  gap:16px;
}
.qr .column{
  display: flex;
  width: calc(45vw);
  color:white;
  justify-content:center;
  align-items: center;

  background-color: rgba(0, 0, 0, 0.8);
  height: calc(100vh - 160px);
  padding:calc(1vw);
  border-radius: 32px;
  flex-direction: column;


}

.h1,.h2,.h3,.section-title{
  margin:0;
}
.code{
  font-family:Georgia, 'Times New Roman', Times, serif
}
/* Major Titles */
.h1 {
  font-size: 5vw; /* Adjusts with viewport width */
  text-shadow: 0.1em 0.1em #000;

}

.h2 {
  font-size: 4vw;
}

.h3 {
  font-size:clamp(54px, calc(1vw + 0.5vh), 18px);
  text-shadow: 0.2em 0.2em #000;
}

/* Section Titles */
.section-title {
  font-size: 3vw;
}

/* Subtitles */
.subtitle {
  font-size: 2.5vw;
}

/* Body Text */
.body-text {
  font-size: 1.8vw;
}
.logo{
  width:20vw;
}
.textbox{

  border: 2px solid white;
  padding:20px;
  border-radius: 10px;
  letter-spacing: 0.2em;
}
.white{
  color:white;
}
.yellow{

}
.column {
  opacity: 0;
  animation: fadeIn 0.5s ease-in forwards;
}

.column:nth-child(2) {
  animation-delay: 250ms;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;

  }
}

@property --glow {
  /* must register --glow to animate it */
  syntax: "<angle>";
  initial-value: 0deg;
  /* used only on pseudo, nowhere to be inherited, 
   * better perf if set false, see 
   * https://www.bram.us/2024/10/03/benchmarking-the-performance-of-css-property/ */
  inherits: false;
}
.card {

  /* needed for absolutely positioned pseudo */
  position: relative;
  /* set dimensions IF even necessary to set, 
   * not given by content or parent layout */

  aspect-ratio: 3/2;
  /* transparent, just to reserve the space */
  border: solid 4px #0000;
  padding: 0.75em;
  /* space between border & text */
  border-radius: 1em;
  /* round corners */
  /* (semi)transparent background, can be 
   * a gradient with (semi)transparent stops */
  background: #000000CC;
  /* turn one pseudo layer into glow */
}
.card::before, .card::after {
  /* full opaque coverage of its box */
  --full: conic-gradient(red 0 0);
  /* grid doesn't work for stacking 
   * when a stacked item is text node */
  position: absolute;
  /* equal to the border-width with minus */
  inset: -8px;
  /* inherit parent box model & rounded corners */
  border: inherit;
  border-radius: inherit;
  /* reserve no-clip space for glow */
  box-shadow: 0 0 8em rgba(0, 0, 0, 0.001);
  background: conic-gradient(from var(--glow),  #FFCB47, #00E096,#22bcef,#FFFFFF) border-box;
  /* make everything inside padding-box transparent
   * by subtracting padding-box from no-clip box */
  mask: var(--full) no-clip subtract, var(--full) padding-box;
  /* don't get in the way of selecting text */
  pointer-events: none;
  /* animate gradient start angle */
  animation: glow 4s linear infinite;
  content: "";
}
.card::after {
  filter: blur(2em);
}
.answered-avatar {
  position: relative;
  background: conic-gradient(from var(--angle), #FFCB47, #00E096, #22bcef, #FFFFFF);
  animation: rotate 4s linear infinite;
}

@keyframes rotate {
  to {
    --angle: 360deg;
  }
}

@property --angle {
  syntax: '<angle>';
  initial-value: 0deg;
  inherits: false;
}
@keyframes glow {
  to {
    --glow: 1turn ;
  }
}




@keyframes moveInCircle {
  0% {
    transform: rotate(0deg);
  }
  50% {
    transform: rotate(180deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes moveVertical {
  0% {
    transform: translateY(-50%);
  }
  50% {
    transform: translateY(50%);
  }
  100% {
    transform: translateY(-50%);
  }
}

@keyframes moveHorizontal {
  0% {
    transform: translateX(-50%) translateY(-10%);
  }
  50% {
    transform: translateX(50%) translateY(10%);
  }
  100% {
    transform: translateX(-50%) translateY(-10%);
  }
}


.gradient-bg {
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;
  background: linear-gradient(40deg, var(--color-bg1), var(--color-bg2));
  top: 0;
  left: 0;

  svg {
    position: fixed;
    top:0;
    left:0;
    width: 0;
    height: 0;
  }


  .gradients-container {
    filter: url(#goo) blur(40px) ;
    width: 100%;
    height: 100%;
  }

  .g1 {
    position: absolute;
    background: radial-gradient(circle at center, rgba(var(--color1), 0.8) 0, rgba(var(--color1), 0) 50%) no-repeat;
    mix-blend-mode: var(--blending);

    width: var(--circle-size);
    height: var(--circle-size);
    top: calc(50% - var(--circle-size) / 2);
    left: calc(50% - var(--circle-size) / 2);

    transform-origin: center center;
    animation: moveVertical 30s ease infinite;

    opacity: 1;
  }

  .g2 {
    position: absolute;
    background: radial-gradient(circle at center, rgba(var(--color2), 0.8) 0, rgba(var(--color2), 0) 50%) no-repeat;
    mix-blend-mode: var(--blending);

    width: var(--circle-size);
    height: var(--circle-size);
    top: calc(50% - var(--circle-size) / 2);
    left: calc(50% - var(--circle-size) / 2);

    transform-origin: calc(50% - 400px);
    animation: moveInCircle 20s reverse infinite;

    opacity: 1;
  }

  .g3 {
    position: absolute;
    background: radial-gradient(circle at center, rgba(var(--color3), 0.8) 0, rgba(var(--color3), 0) 50%) no-repeat;
    mix-blend-mode: var(--blending);

    width: var(--circle-size);
    height: var(--circle-size);
    top: calc(50% - var(--circle-size) / 2 + 200px);
    left: calc(50% - var(--circle-size) / 2 - 500px);

    transform-origin: calc(50% + 400px);
    animation: moveInCircle 40s linear infinite;

    opacity: 1;
  }

  .g4 {
    position: absolute;
    background: radial-gradient(circle at center, rgba(var(--color4), 0.8) 0, rgba(var(--color4), 0) 50%) no-repeat;
    mix-blend-mode: var(--blending);

    width: var(--circle-size);
    height: var(--circle-size);
    top: calc(50% - var(--circle-size) / 2);
    left: calc(50% - var(--circle-size) / 2);

    transform-origin: calc(50% - 200px);
    animation: moveHorizontal 40s ease infinite;

    opacity: 0.7;
  }

  .g5 {
    position: absolute;
    background: radial-gradient(circle at center, rgba(var(--color5), 0.8) 0, rgba(var(--color5), 0) 50%) no-repeat;
    mix-blend-mode: var(--blending);

    width: calc(var(--circle-size) * 2);
    height: calc(var(--circle-size) * 2);
    top: calc(50% - var(--circle-size));
    left: calc(50% - var(--circle-size));

    transform-origin: calc(50% - 800px) calc(50% + 200px);
    animation: moveInCircle 20s ease infinite;

    opacity: 1;
  }

  .interactive {
    position: absolute;
    background: radial-gradient(circle at center, rgba(var(--color-interactive), 0.8) 0, rgba(var(--color-interactive), 0) 50%) no-repeat;
    mix-blend-mode: var(--blending);

    width: 100%;
    height: 100%;
    top: -50%;
    left: -50%;

    opacity: 0.7;
  }
}



